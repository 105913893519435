import * as React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from 'react-router-dom';

import './config/axios.ts';

import AuthService from './services/auth';

import { PathPrivate, PathPublic } from './paths/constants';
import { getPathDashboard } from './paths/helpers';

import {
  LayoutPrivate,
  loader as loaderLayoutPrivate,
} from './layout/LayoutPrivate';
import { LayoutPublic } from './layout/LayoutPublic';

import { Root } from './pages/Root';
import { NotFound } from './pages/NotFound';

import { Card } from './pages/public/Card/Card';
import { Credit } from './pages/public/Credit';
import { Login } from './pages/public/Login';
import { Logout } from './pages/public/Logout';
import { Denied } from './pages/public/Denied';

import { Dashboard } from './pages/private/Dashboard';
import { Drivers } from './pages/private/Drivers';
import { Driver } from './pages/private/Driver';
import { Members } from './pages/private/Members';
import { Settings } from './pages/private/Settings';
import { Stations } from './pages/private/Stations';
import { Tractors } from './pages/private/Tractors';
import { Tractor } from './pages/private/Tractor';
import Reports from './pages/private/Reports';
import { OneTimeLimit } from './pages/private/OneTimeLimit';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const getRouter = () =>
  createBrowserRouter([
    {
      path: PathPublic.Index,
      element: <Root />,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          async loader() {
            if (AuthService.isLoggedIn()) {
              return redirect(getPathDashboard());
            }
            return redirect(PathPublic.Login);
          },
        },
        {
          element: <LayoutPrivate />,
          loader: loaderLayoutPrivate,
          children: [
            {
              path: PathPrivate.Dashboard,
              element: <Dashboard />,
            },
            {
              path: PathPrivate.Drivers,
              element: <Drivers />,
            },
            {
              path: PathPrivate.Driver,
              element: <Driver />,
            },
            {
              path: PathPrivate.Members,
              element: <Members />,
            },
            {
              path: PathPrivate.Settings,
              element: <Settings />,
            },
            {
              path: PathPrivate.Stations,
              element: <Stations />,
            },

            {
              path: PathPrivate.Tractors,
              element: <Tractors />,
            },
            {
              path: PathPrivate.Tractor,
              element: <Tractor />,
            },
            {
              path: PathPrivate.Reports,
              element: <Reports />,
            },
            {
              path: PathPrivate.OneTimeLimit,
              element: <OneTimeLimit />,
            },
          ],
        },
        {
          element: <LayoutPublic />,
          children: [
            {
              path: PathPublic.Login,
              element: <Login />,
              async loader() {
                if (AuthService.isLoggedIn()) {
                  return redirect(getPathDashboard());
                }
                return null;
              },
            },
            {
              path: PathPublic.Card,
              element: <Card />,
            },
            {
              path: PathPublic.Logout,
              element: <Logout />,
            },
          ],
        },
        {
          element: <LayoutPublic isV2 />,
          children: [
            {
              path: PathPublic.Credit,
              element: <Credit />,
            },
          ],
        },
        {
          path: PathPublic.Denied,
          element: <Denied />,
        },
      ],
    },
  ]);

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <RouterProvider router={getRouter()} />
    </React.StrictMode>,
  );

AuthService.initKeycloak(renderApp);

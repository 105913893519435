import { AxiosResponse } from 'axios';
import { Config } from '@/fleet/config/env';
import { publicAxios } from '@/fleet/config/axios';

import { CombinedFormValuesType } from './';

const apiUrl = Config.CARRIER_API_URL;

// Define the mapping between frontend and backend fields
const fieldMapping: Record<
  string,
  | keyof CombinedFormValuesType
  | [keyof CombinedFormValuesType, keyof CombinedFormValuesType]
> = {
  // Step 1
  primary_contact_name: ['firstName', 'lastName'],
  primary_contact_email: 'email',
  primary_contact_phone_number: 'phone',

  // Step 2
  department_of_transportation_number: 'usDotNumber',
  motor_carrier_number: 'mcNumber',

  // Step 2 - Business Information
  business_name: 'businessName',
  dba: 'dba',
  business_ein: 'federalIdNumber',
  state_incorporated: 'stateOfIncorporation',
  number_of_drivers: 'numberOfTrucks',
  years_in_business: 'yearsInBusiness',
  business_phone_number: 'businessPhoneNumber',
  industry_type: 'industryType',
  type_of_business: 'typeOfBusiness',
  annual_revenue: 'annualRevenue',
  estimated_weekly_spent: 'weeklyFuelSpend',

  // Step 2 - Business Address
  business_zip_code: 'businessZip',
  business_city: 'businessCity',
  business_region: 'businessState',
  business_country: 'businessCountry',
  business_address1: 'businessAddress1',
  business_address2: 'businessAddress2',

  // Step 3 - First User
  owner_legal_name: ['owner1FirstName', 'owner1LastName'],
  owner_date_of_birth: 'owner1DateOfBirth',
  owner_ssn: 'owner1SSN',
  owner_zip_code: 'owner1Zip',
  owner_city: 'owner1City',
  owner_region: 'owner1State',
  owner_address1: 'owner1AddressLine1',
  owner_address2: 'owner1AddressLine2',
  owner_country: 'owner1Country',
  owner_email: 'owner1Email',
  owner_phone: 'owner1MobilePhoneNumber',
  owner_ownership: 'owner1OwnershipPercentage',

  // Step 3 - Second User
  owner_two_legal_name: ['owner2FirstName', 'owner2LastName'],
  owner_two_date_of_birth: 'owner2DateOfBirth',
  owner_two_ssn: 'owner2SSN',
  owner_two_zip_code: 'owner2Zip',
  owner_two_city: 'owner2City',
  owner_two_region: 'owner2State',
  owner_two_address1: 'owner2AddressLine1',
  owner_two_address2: 'owner2AddressLine2',
  owner_two_country: 'owner2Country',
  owner_two_email: 'owner2Email',
  owner_two_phone: 'owner2MobilePhoneNumber',
  owner_two_ownership: 'owner2OwnershipPercentage',
};

// Function to transform frontend data to backend format
function transformData(
  frontendData: CombinedFormValuesType,
): Record<string, any> {
  const backendData: Record<string, any> = {};

  for (const [backendField, frontendField] of Object.entries(fieldMapping)) {
    if (Array.isArray(frontendField)) {
      backendData[backendField] = frontendField
        .map((f) => frontendData[f])
        .filter(Boolean)
        .join(' ');
    } else if (frontendField in frontendData) {
      backendData[backendField] = frontendData[frontendField];
    }
  }

  return backendData;
}

export const createCreditApplicationService = async (
  payload: CombinedFormValuesType,
): Promise<AxiosResponse> => {
  const transformedData = transformData(payload);
  try {
    const response = await publicAxios.post(
      `${apiUrl}/applications/credit`,
      transformedData,
    );
    return response;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { SmallSkeleton } from './SmallSkeleton';
import { commonStyles } from './shared/commonStyles';
import { getDuration } from './shared/getDuration';

type TypeDataDashboard = {
  totalDollars?: string;
  totalDiscounted?: string;
};

type TypePropsSavings = {
  dashboardData: TypeDataDashboard | undefined;
  selectedIndex: string;
};

export function Savings(props: TypePropsSavings) {
  const { dashboardData, selectedIndex } = props;

  const totalSavings = useMemo(() => {
    const dollars = parseFloat(dashboardData?.totalDollars ?? '0');
    const discounted = parseFloat(dashboardData?.totalDiscounted ?? '0');
    const savings = dollars - discounted;
    return isNaN(savings) ? '$0.00' : `$${savings.toFixed(2)}`;
  }, [dashboardData?.totalDollars, dashboardData?.totalDiscounted]);

  return (
    <Box sx={commonStyles.container}>
      <Typography sx={commonStyles.title}>Savings</Typography>
      <Box sx={commonStyles.content}>
        <Typography sx={commonStyles.textCenter}>
          LAST {getDuration(selectedIndex)}
        </Typography>
        {totalSavings ? (
          <Typography sx={commonStyles.value}>{totalSavings}</Typography>
        ) : (
          <SmallSkeleton />
        )}
      </Box>
    </Box>
  );
}

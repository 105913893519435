import { Line } from 'react-chartjs-2';
import { Box, Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { useMemo } from 'react';
import 'chart.js/auto';

type TypePropsTransactionChart = {
  chartData: any;
  xAxisFormat: string;
};

export function TransactionChart(props: TypePropsTransactionChart) {
  const { chartData, xAxisFormat } = props;
  const parsedData = useMemo(() => {
    if (!chartData || chartData.length === 0) {
      return null;
    }

    return chartData.map((d: { time: string | number | Date; gallon: any }) => {
      return { x: format(new Date(d.time), xAxisFormat), y: d.gallon };
    });
  }, [chartData, xAxisFormat]);

  const data = {
    type: 'line',
    datasets: [
      {
        data: parsedData || [],
        fill: false,
        borderColor: '#42A5F5',
        tension: 0.1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 8,
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Box minWidth={750}>
      {chartData && parsedData ? (
        <Line data={data} options={options} />
      ) : (
        <Skeleton
          variant="rounded"
          animation="wave"
          width={750}
          height={375}
          sx={{ marginBottom: 1.5 }}
        />
      )}
    </Box>
  );
}

import * as Yup from 'yup';

export const validationSchema = Yup.object({
  businessName: Yup.string()
    .max(60, 'Must be 60 characters or less')
    .required('Required'),
  dba: Yup.string()
    .max(60, 'Must be 60 characters or less')
    .required('Required'),
  numberOfTrucks: Yup.number()
    .typeError('Required')
    .min(0, 'Number of trucks must be 0 or greater')
    .max(99999, 'Maximum allowed 99999')
    .required('Required'),
  stateOfIncorporation: Yup.string().nullable().required('Required'),
  federalIdNumber: Yup.string()
    .required('Required')
    .test('is-valid-federal-id', 'Invalid Federal ID number', (value) => {
      if (!value) return true;
      return /^\d{2}-\d{7}$/.test(String(value));
    }),
  industryType: Yup.string().nullable().required('Required'),
  typeOfBusiness: Yup.string().nullable().required('Required'),
  yearsInBusiness: Yup.string()
    .nullable()
    .required('Required')
    .test('is-valid-years', 'Maximum 99 years', (value) => {
      if (!value) return true;
      const numValue = parseInt(value, 10);
      return !isNaN(numValue) && numValue > 0 && numValue <= 99;
    }),
  annualRevenue: Yup.string()
    .test('is-number', 'Must be a valid number', (value) => {
      if (!value) return true;
      const number = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return !isNaN(number);
    })
    .test('is-positive', 'Must be a positive number', (value) => {
      if (!value) return true;
      const number = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return number >= 0;
    })
    .test('max-value', 'Maximum allowed is $1,000,000,000', (value) => {
      if (!value) return true;
      const number = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return number <= 1000000000;
    })
    .required('Required'),
  weeklyFuelSpend: Yup.string()
    .test('is-number', 'Must be a valid number', (value) => {
      if (!value) return true;
      const number = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return !isNaN(number);
    })
    .test('is-positive', 'Must be a positive number', (value) => {
      if (!value) return true;
      const number = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return number >= 0;
    })
    .test('max-value', 'Maximum allowed is $1,000,000', (value) => {
      if (!value) return true;
      const number = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return number <= 1000000;
    })
    .required('Required'),
  businessAddress1: Yup.string()
    .max(60, 'Must be 60 characters or less')
    .required('Required'),
  businessAddress2: Yup.string().max(60, 'Must be 60 characters or less'),
  businessCity: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .required('Required'),
  businessState: Yup.string().nullable().required('Required'),
  businessZip: Yup.string()
    .required('Required')
    .test('valid-zip', 'Invalid USA Zip Code', (value) => {
      if (!value) return true;
      return /^\d{5}(-\d{4})?$/.test(value);
    }),
  businessCountry: Yup.string().nullable().required('Required'),
  businessPhoneNumber: Yup.string()
    .nullable()
    .required('Required')
    .test('is-valid-phone', 'Invalid phone number', (value) => {
      if (!value) return true;
      return /^\d{3}-\d{3}-\d{4}$/.test(value);
    }),
  usDotNumber: Yup.string()
    .nullable()
    .required('Required')
    .test('is-valid-dot', 'US DOT number must be 5 to 8 digits', (value) => {
      if (!value) return true;
      return /^\d{5,8}$/.test(value);
    }),
  mcNumber: Yup.string()
    .nullable()
    .test('valid-mc-number', 'Invalid MC Number format', (value) => {
      if (!value) return true;
      return /^(MC-)?[0-9]{1,7}$/.test(value);
    }),
});

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatValue = (value: string): string => {
  const numericValue = parseFloat(value);
  if (numericValue < 0) {
    return `-$${Math.abs(numericValue).toFixed(2)}`;
  }
  return `$${numericValue.toFixed(2)}`;
};

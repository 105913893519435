import { get } from 'lodash';
import { usePapaParse } from 'react-papaparse';

interface IProps {
  data: any;
  columns: {
    name: string;
    label: string;
  }[];
  fileName: string;
  fromJson: boolean;
}

export const ExportDataAsCSV = ({
  data,
  columns,
  fileName,
  fromJson,
}: IProps): void => {
  const { jsonToCSV } = usePapaParse();

  let exportableData: any[] = [];

  if (Array.isArray(data)) {
    // If data is an array, map over it
    exportableData = data.map((line: any) => {
      const newLine: any = {};
      columns.forEach(
        (column: any) => (newLine[column.label] = get(line, column.name)),
      );
      return newLine;
    });
  } else if (typeof data === 'object' && data !== null) {
    // If data is an object, convert it into an array with a single object
    const newLine: any = {};
    columns.forEach(
      (column: any) => (newLine[column.label] = get(data, column.name)),
    );
    exportableData = [newLine]; // Wrap the object in an array
  }

  let csvContent = '';

  if (fromJson) {
    // Convert the exportableData to CSV format
    csvContent = jsonToCSV(exportableData);
  } else {
    // If not converting from JSON, use the exportableData as is
    csvContent = JSON.stringify(exportableData);
  }

  const csvData = new Blob([csvContent], {
    type: 'text/csv;charset=utf-8;',
  });

  const csvURL = window.URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL as string;
  link.setAttribute('download', `${fileName}.csv`);
  link.click();
  link.remove();
};

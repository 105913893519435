import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Skeleton,
} from '@mui/material';
import { currencyFormatter } from '@/fleet/components/helpers/currencyFormatter';
import * as queries from './queries';

const commonStyles = {
  title: {
    fontWeight: 600,
    mb: 2,
    fontSize: 14,
    textTransform: 'uppercase',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 150,
    my: 2,
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    p: 2,
  },
};

function parseNumber(value: string | number | null) {
  // Convert the value to a number, if it results in NaN, return 0
  return isNaN(Number(value)) ? 0 : Number(value);
}

export function Financial() {
  const { carrierUuid = '' } = useParams();
  const [loadingCreditLimit, setLoadingCreditLimit] = React.useState(true);
  const [loadingBalance, setLoadingBalance] = React.useState(true);
  const [creditLimit, setCreditLimit] = React.useState(0);
  const [balance, setBalance] = React.useState({
    balanceAmount: 0,
    availableFunds: 0,
  });

  React.useEffect(() => {
    const fetchCreditLimit = async () => {
      try {
        const response = await queries.getCarrierCreditLineService(carrierUuid);
        if (response.isAxiosError) {
          throw new Error(response.response.data?.message);
        }
        setCreditLimit(parseNumber(response.data?.creditLine?.amount));
      } catch (err) {
        setCreditLimit(0);
      } finally {
        setLoadingCreditLimit(false);
      }
    };

    const fetchBalance = async () => {
      try {
        const response = await queries.getCarrierBalance(carrierUuid);
        if (response.isAxiosError) {
          throw new Error(response.response.data?.message);
        }
        setBalance({
          balanceAmount: parseNumber(response.data?.balanceAmount),
          availableFunds: parseNumber(response.data?.availableFunds),
        });
      } catch (err) {
        setBalance({
          balanceAmount: 0,
          availableFunds: 0,
        });
      } finally {
        setLoadingBalance(false);
      }
    };

    fetchCreditLimit();
    fetchBalance();
  }, []);

  const items = [
    {
      title: 'Credit Limit',
      value: creditLimit,
      isLoading: loadingCreditLimit,
    },
    {
      title: 'Balance',
      value: balance.balanceAmount,
      isLoading: loadingBalance,
    },
    {
      title: 'Available Funds',
      value: balance.availableFunds,
      isLoading: loadingBalance,
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography sx={commonStyles.title}>Financial Overview</Typography>
      <Grid container spacing={2}>
        {items.map((item) => (
          <Grid item xs={12} sm={4} key={item.title}>
            <Card>
              <CardContent>
                <Typography variant="h5">{item.title}</Typography>
                {item.isLoading ? (
                  <Skeleton variant="text" width={100} height={40} />
                ) : (
                  <Typography variant="h6">
                    {currencyFormatter.format(item.value)}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

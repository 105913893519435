import axios from 'axios';
import AuthService from '@/fleet/services/auth';

// Configure default Axios instance with authorization
axios.defaults.withCredentials = window.location.host.includes('fleetapp');

axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthService.getToken()}`,
    };
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

// Configure Axios instance for FMCSA API
export const fmcsaAxios = axios.create({
  baseURL: 'https://mobile.fmcsa.dot.gov',
  withCredentials: false, // Ensure credentials are not sent for this domain
});

fmcsaAxios.interceptors.request.use(
  async (config) => {
    config.headers = {
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => Promise.reject(error),
);

// Separate Axios instance for public API requests
export const publicAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

import React from 'react';
import { Typography, Grid } from '@mui/material';
import { currencyFormatter } from '@/fleet/components/helpers/currencyFormatter';

function FleetDetails(props: any) {
  const { formValues } = props;
  const {
    driverId,
    numberOfDrivers,
    departmentOfTransportationNumber,
    motorCarrierNumber,
    carrierType,
    estimatedWeeklySpent,
  } = formValues;

  return (
    <Grid item container direction="column" xs={12}>
      <Typography variant="h6" gutterBottom fontWeight={500}>
        Fleet Details
      </Typography>
      <Grid container>
        {carrierType === 'owner_operator' ? (
          <>
            <Grid item xs={3}>
              <Typography gutterBottom>Driver ID</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography gutterBottom>{driverId}</Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3}>
              <Typography gutterBottom>Number Of Drivers:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography gutterBottom>{numberOfDrivers}</Typography>
            </Grid>
          </>
        )}

        <>
          <Grid item xs={3}>
            <Typography gutterBottom>DOT Number:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>
              {departmentOfTransportationNumber}
            </Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>MC Number:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>{motorCarrierNumber}</Typography>
          </Grid>
        </>
        <>
          <Grid item xs={3}>
            <Typography gutterBottom>
              Typical Weekly Fuel and Maintenance Spend:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom>
              {currencyFormatter.format(estimatedWeeklySpent)}
            </Typography>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
}

export default FleetDetails;
